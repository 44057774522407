global.$ = global.jQuery = require('jquery');

const Formstone = require('formstone');
const magnificPopup = require('magnific-popup');

require('formstone/dist/js/core.js');
require('formstone/dist/js/background.js');
require('formstone/dist/js/equalize.js');
require('formstone/dist/js/swap.js');
require('formstone/dist/js/navigation.js');
// require('formstone/dist/js/mediaquery.js');
// require('formstone/dist/js/tabs.js');


require('jquery-hoverintent/jquery.hoverIntent.js');

let Slick = require('slick-carousel');

import AOS from "aos";

AOS.init({
  disable: "phone", // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 60, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});


$(document).ready(function(jQuery) {

    $(".background").background();
    $(".equalize").equalize();
    $(".swap").swap();

    $("#mobilenav").navigation({
      type: "overlay",
      gravity: "right",
      maxWidth: "1024px",
      label: false
    });

    $("#closemenu").click(function(event) {
        $("#mobilenav").navigation("close");
        event.preventDefault();
    });

    $(".dropdown-toggle").hoverIntent(function() {
        $('.dropdown-menu').addClass('hover');
    }, function() {
        setTimeout(() => {
            $('.dropdown-menu').removeClass('hover')
        }, 2000);
    });


    // $('.slider-nav').on('afterChange', function(event, slick, currentSlide, nextSlide){
    //   var prevSlideEl = slick.$slides[currentSlide];
    //   var nextSlideEl = slick.$slides[nextSlide + 1];
    //   slick.$slides.each(function(index, el) {

    //   });
    //   $(prevSlideEl).addClass('slick-prev-slide');
    //   $(nextSlideEl).addClass('slick-next-slide');

    // });

    const TestimonialSlider = $('.carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 400,
      dots: false,
      arrows: false,
      vertical: false,
      centerMode: false,
      fade: false,
    });

    $('.carousel-nav a').on('click', function(event) {
      let slidenumber = $(this).data('slideto');
      TestimonialSlider.slick('slickGoTo',slidenumber);
      event.preventDefault();
    });

    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      vertical: true,
      centerMode: false,
      fade: false,
      asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      // adaptiveHeight: true,
      centerPadding: '50px',
      asNavFor: '.slider-for',
      infinite: true,
      dots: false,
      arrows: false,
      vertical: true,
      centerMode: true,
      focusOnSelect: true,
    });


    $('.inline-modal').magnificPopup({
      type:'inline',
      closeBtnInside:false,
      showCloseBtn: false,
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });
    $('.close-btn').on( "click", function(event) {
      $.magnificPopup.close();
      event.preventDefault();
    });
    $('.video-link').magnificPopup({
      type:'iframe'
    });


    $.fn.isInViewport = function () {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $('.videotoggle').hover(function() {
        var videoplay = $(this).find('.video-play');
        var videohover = $(this).find('.video-hover');
        var video = videohover.find('video');
        var src = video.get(0);
        src.play();
        videoplay.hide();
        videohover.show();
    }, function() {
        var videoplay = $(this).find('.video-play');
        var videohover = $(this).find('.video-hover');
        videohover.hide();
        videoplay.show();
    });

    // start video when in viewport
    // $(window).on("load resize scroll",function(e){

    //     $.each($('.videotoggle'), function(index, el) {
    //         if ($(el).isInViewport()) {
    //             var videoplay = $(this).find('.video-play');
    //             var video = videoplay.find('video');
    //             var src = video.get(0);
    //             if (!src.currentTime > 0) {
    //                 src.play();
    //             }
    //         }
    //     });

    // });

    const videoToggles = document.querySelectorAll('.videotoggle');

    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {

          var el = $(entry.target);
          var videoplay = el.find('.video-play');
          var video = videoplay.find('video');
          var src = video.get(0);
          if (!src.currentTime > 0) {
              src.play();
          }
          // console.log('in the view');
        } else {
          // console.log('out of view');
        }
      });
    });

    videoToggles.forEach(toggles => {
      observer.observe(toggles);
    });
});

$(window).on('load', function () {
  AOS.refresh();
});